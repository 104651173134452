import request from '@/utils/request'

export function getRegion(params) {
    return request({
        url: '/api/area/getRegion',
        method: 'post',
        params: params
    })
}

export function getAreaById(params) {
    return request({
        url: '/api/area/getAreaById',
        method: 'post',
        params: params
    })
}

export function getUsedRegion(params){
    return request({
        url: '/api/area/getUsedRegion',
        method: 'post',
        params: params
    })
}
export function getSupRegion(params){
    return request({
        url: '/api/area/getSupRegion',
        method: 'post',
        params: params
    })
}