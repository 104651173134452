import request from '@/utils/request'
import qs from 'qs'

export function selectSuperiorSupplier(params) {
    return request({
        url: '/api/supplier/selectSuperiorSupplier',
        method: 'post',
        params: params
    })
}

export function selectAllSupplier(params) {
    return request({
        url: '/api/supplier/selectAll',
        method: 'post',
        params: params
    })
}

export function getList(params) {
    return request({
        url: '/api/supplier/getList',
        method: 'post',
        params: params
    })
}

export function add(params) {
    return request({
        url: '/api/supplier/add',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getOneById(params) {
    return request({
        url: '/api/supplier/getOneById',
        method: 'post',
        params: params
    })
}

export function update(params) {
    return request({
        url: '/api/supplier/update',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getProviders(params) {
    return request({
        url: '/api/supplier/getProviders',
        method: 'post',
        params: params
    })
}

/**
 * 获取服务商列表
 */
export function selectAllSuppliers(params) {
    return request({
        url: '/api/supplier/selectAllSuppliers',
        method: 'post',
        params: params
    })
}